import * as React from 'react';

import {
  colorTheme,
  finalcadBusinessOrganisation,
  finalcadGroupsStorage,
  finalcadLastVisitedPage,
  finalcadProjectsListFilters,
  finalcadReportCustomization,
  finalcadSavedProjectFilters,
  finalcadStorageEmailValidationLocation,
  finalcadStorageEmailVerificationLink,
  finalcadStorageLanguageKey,
  finalcadStorageRecentlyViewedOrganizations,
  finalcadStorageRecentlyViewedPlans,
  finalcadStorageRecentlyViewedProjects,
  finalcadThemePreference,
  finalcadTradeLanguageKey,
  finalcadUserStore,
  firebaseFeatureStorage,
  oktaCacheStorage,
  oktaTokenStorage,
  possibleLanguages,
} from 'utils/constants';
import { jsonToMap, mapToJson } from 'utils/mapTransformer';

const getOktaTokenStorage = () => JSON.parse(localStorage.getItem(oktaTokenStorage)) || null;
const cleanExpiredProjectFilter = () => {
  const oktaToken = getOktaTokenStorage();
  const allProjectFilters = localStorage.getItem(finalcadSavedProjectFilters) || null;
  const parsedProjectFilters = allProjectFilters ? JSON.parse(allProjectFilters) : {};

  if (oktaToken?.accessToken) {
    const fcUserId = oktaToken?.accessToken?.claims?.fcUserId;
    if (parsedProjectFilters && parsedProjectFilters[fcUserId]) {
      const userFiltersProjects = parsedProjectFilters[fcUserId];
      Object.keys(userFiltersProjects).forEach(key => {
        if (
          userFiltersProjects[key].expirationDate &&
          new Date(userFiltersProjects[key].expirationDate) > new Date()
        ) {
          delete userFiltersProjects[key];
        }
      });
      localStorage.setItem(finalcadSavedProjectFilters, JSON.stringify(parsedProjectFilters));
    }
  }
};

function getCurrentUserId() {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    return oktaToken?.accessToken?.claims?.fcUserId;
  }
  return null;
}

const saveFilterByProjectAndModule = ({
  filters,
  projectId,
  fcUserId,
  module,
  expirationInDays,
}) => {
  const userId = fcUserId || getCurrentUserId();
  const storageFilters = localStorage.getItem(finalcadSavedProjectFilters);
  const storageMap = storageFilters ? jsonToMap(storageFilters) : new Map();

  // Key is a combination of project id, user id and module name
  const keyMap = { projectId, userId, module };

  // Define expiration date if needed
  let expirationDate = null;
  if (expirationInDays) {
    const expDate = new Date();
    expDate.setDate(expDate.getDate() + expirationInDays);
    expirationDate = expDate;
  }

  const data = expirationDate ? { filters, expirationDate } : { filters };
  storageMap.set(JSON.stringify(keyMap), JSON.stringify(data));
  localStorage.setItem(finalcadSavedProjectFilters, mapToJson(storageMap));
};

const getFilterByProjectAndModule = ({ projectId, fcUserId, module }) => {
  const userId = fcUserId || getCurrentUserId();
  const storageFilters = localStorage.getItem(finalcadSavedProjectFilters);
  const storageMap = storageFilters ? jsonToMap(storageFilters) : new Map();
  const keyMap = { projectId, userId, module };
  const stringifyData = storageMap.get(JSON.stringify(keyMap));
  const data = stringifyData ? JSON.parse(stringifyData) : {};
  const expirationDate = data ? data.expirationDate : null;
  const currentDate = new Date();

  // Check if data is outdated if expirationDate is set
  if (expirationDate && new Date(expirationDate) < currentDate) {
    return null;
  }

  return data ? data.filters : null;
};

const saveProjectsListFilters = ({ filters, fcUserId, organizationId, expirationInDays }) => {
  const userId = fcUserId || getCurrentUserId();
  const storageFilters = localStorage.getItem(finalcadProjectsListFilters);
  const storageMap = storageFilters ? jsonToMap(storageFilters) : new Map();

  const keyMap = { userId, organizationId };

  // Define expiration date if needed
  let expirationDate = null;
  if (expirationInDays) {
    const expDate = new Date();
    expDate.setDate(expDate.getDate() + expirationInDays);
    expirationDate = expDate;
  }

  const data = expirationDate ? { filters, expirationDate } : { filters };
  storageMap.set(JSON.stringify(keyMap), JSON.stringify(data));
  localStorage.setItem(finalcadProjectsListFilters, mapToJson(storageMap));
};

const getProjectsListFilters = ({ fcUserId, organizationId }) => {
  const userId = fcUserId || getCurrentUserId();
  const storageFilters = localStorage.getItem(finalcadProjectsListFilters);
  const storageMap = storageFilters ? jsonToMap(storageFilters) : new Map();
  const keyMap = { userId, organizationId };
  const stringifyData = storageMap.get(JSON.stringify(keyMap));
  const data = stringifyData ? JSON.parse(stringifyData) : {};
  const expirationDate = data ? data.expirationDate : null;
  const currentDate = new Date();

  // Check if data is outdated if expirationDate is set
  if (expirationDate && new Date(expirationDate) < currentDate) {
    return null;
  }

  return data ? data.filters : null;
};

const saveReportCustomization = ({ projectId, configs, key = {} }) => {
  const userId = getCurrentUserId();
  const currentReportCustomization = localStorage.getItem(finalcadReportCustomization);
  const keyMap = { ...key, projectId, userId };
  const reportMap = currentReportCustomization ? jsonToMap(currentReportCustomization) : new Map();
  reportMap.set(JSON.stringify(keyMap), JSON.stringify(configs));
  localStorage.setItem(finalcadReportCustomization, mapToJson(reportMap));
};

const getReportCustomization = ({ projectId, key = {} }) => {
  const userId = getCurrentUserId();
  const currentReportCustomization = localStorage.getItem(finalcadReportCustomization);
  const reportMap = currentReportCustomization ? jsonToMap(currentReportCustomization) : new Map();
  const keyMap = { ...key, projectId, userId };
  const stringifyKeyMap = JSON.stringify(keyMap);
  const data = reportMap.get(stringifyKeyMap);
  return data ? JSON.parse(data) : null;
};

const setTmpNavigationItems = items => {
  localStorage.setItem('tmpNavigationItems', JSON.stringify(items));
};

const getTmpNavigationItems = () => localStorage.getItem('tmpNavigationItems');

const clearFilterByProjectAndModule = ({ projectId, fcUserId, module }) => {
  const userId = fcUserId || getCurrentUserId();
  const storageFilters = localStorage.getItem(finalcadSavedProjectFilters);
  const storageMap = storageFilters ? jsonToMap(storageFilters) : new Map();
  const keyMap = { projectId, userId, module };
  storageMap.delete(JSON.stringify(keyMap));
  localStorage.setItem(finalcadSavedProjectFilters, mapToJson(storageMap));
};

const getThemePreference = () => {
  if (localStorage.getItem(finalcadThemePreference))
    return localStorage.getItem(finalcadThemePreference);
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? colorTheme.dark
    : colorTheme.light;
};

const setThemePreference = theme => {
  localStorage.setItem(finalcadThemePreference, theme);
};

const setEmailVerificationLink = value => {
  localStorage.setItem(finalcadStorageEmailVerificationLink, JSON.stringify(value));
};

const getEmailVerificationLink = () => {
  let emailVerificationLink = localStorage.getItem(finalcadStorageEmailVerificationLink);
  if (!emailVerificationLink) {
    return null;
  }

  try {
    emailVerificationLink = emailVerificationLink ? JSON.parse(emailVerificationLink) : null;
  } catch (e) {
    return null;
  }

  return emailVerificationLink;
};
const setEmailValidationLocation = value => {
  localStorage.setItem(finalcadStorageEmailValidationLocation, JSON.stringify(value));
};

const getEmailValidationLocation = () => {
  let emailValidationLocation = localStorage.getItem(finalcadStorageEmailValidationLocation);
  if (!emailValidationLocation) {
    return null;
  }
  try {
    emailValidationLocation = emailValidationLocation ? JSON.parse(emailValidationLocation) : null;
  } catch (e) {
    return null;
  }
  return emailValidationLocation;
};

const setBusinessOrganizationLocalStorage = organization => {
  const existingBusinessOrganisation = getBusinessOrganizationLocalStorage();
  localStorage.setItem(
    finalcadBusinessOrganisation,
    JSON.stringify({
      ...existingBusinessOrganisation,
      ...organization,
    }),
  );
};

const setFinalcadCurrentOffer = offer => {
  const existingBusinessOrganisation = getBusinessOrganizationLocalStorage();
  localStorage.setItem(
    finalcadBusinessOrganisation,
    JSON.stringify({
      ...existingBusinessOrganisation,
      offerDetails: offer,
    }),
  );
};

const getFinalcadCurrentOffer = () => {
  const businessOrganization = getBusinessOrganizationLocalStorage();
  return businessOrganization?.offerDetails;
};

const getBusinessOrganizationLocalStorage = () => {
  const businessString = localStorage.getItem(finalcadBusinessOrganisation);
  let business;
  try {
    business = businessString ? JSON.parse(businessString) : null;
  } catch (e) {
    business = null;
  }
  const businessOrganization = business?.id ? business : {};
  return businessOrganization;
};

const setLanguageUserStorage = language => {
  if (language === null) {
    const formattedNavigatorLanguage = navigator.language?.split('-')[0];
    if (possibleLanguages.includes(formattedNavigatorLanguage)) {
      localStorage.setItem(finalcadStorageLanguageKey, formattedNavigatorLanguage);
    } else {
      localStorage.setItem(finalcadStorageLanguageKey, 'en');
    }
  } else {
    const formattedLanguage = language?.split('-')[0];
    if (possibleLanguages.includes(formattedLanguage)) {
      localStorage.setItem(finalcadStorageLanguageKey, formattedLanguage);
    } else {
      localStorage.setItem(finalcadStorageLanguageKey, 'en');
    }
  }
};

const getLanguageUserFromLocalStorage = () => {
  const language = localStorage.getItem(finalcadStorageLanguageKey);
  if (language !== null) {
    if (possibleLanguages.includes(language.substr(0, 2))) return language.substr(0, 2);
  } else {
    const formattedNavigatorLanguage = navigator.language.split('-')[0];
    if (possibleLanguages.includes(formattedNavigatorLanguage)) {
      return formattedNavigatorLanguage;
    }
  }
  return 'en';
};
const setLanguageTradeStorage = language => {
  localStorage.setItem(finalcadTradeLanguageKey, language);
};

const getLanguageTradeLocalStorage = () => {
  const language = localStorage.getItem(finalcadTradeLanguageKey);
  return language || getLanguageUserFromLocalStorage();
};

const removeBusinessFromLocalStorage = () => {
  localStorage.removeItem(finalcadBusinessOrganisation);
};

const getRecentlyViewedPlans = () => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const { id, plan } = JSON.parse(localStorage.getItem(finalcadStorageRecentlyViewedPlans)) || {};
    if (id !== oktaToken?.accessToken?.claims?.fcUserId) {
      localStorage.setItem(
        finalcadStorageRecentlyViewedPlans,
        JSON.stringify({
          id: oktaToken?.accessToken?.claims?.fcUserId,
          plan: [],
        }),
      );
      return [];
    }
    return plan || [];
  }
  return [];
};

const updateRecentlyViewedPlans = plans => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const recentlyViewedPlans = getRecentlyViewedPlans();
    const expanded = getRecentlyViewExpandedState(finalcadStorageRecentlyViewedPlans);

    plans?.forEach(plan => {
      const planIndex = recentlyViewedPlans.findIndex(
        recentlyViewedPlan => recentlyViewedPlan.id === plan.id,
      );
      if (planIndex > -1) {
        recentlyViewedPlans[planIndex] = {
          ...plan,
          project_id: recentlyViewedPlans[planIndex].project_id,
        };
        localStorage.setItem(
          finalcadStorageRecentlyViewedPlans,
          JSON.stringify({
            id: oktaToken?.accessToken?.claims?.fcUserId,
            plan: recentlyViewedPlans,
            expanded,
          }),
        );
      }
    });
  }
};

const addRecentlyViewedPlans = plan => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const recentlyViewedPlans = getRecentlyViewedPlans();
    const expanded = getRecentlyViewExpandedState(finalcadStorageRecentlyViewedPlans);
    const updatedRecentlyViewPlans = recentlyViewedPlans.filter(
      recentlyViewedPlan => recentlyViewedPlan.id !== plan.id,
    );

    if (updatedRecentlyViewPlans.length === 5) updatedRecentlyViewPlans.pop();

    localStorage.setItem(
      finalcadStorageRecentlyViewedPlans,
      JSON.stringify({
        id: oktaToken?.accessToken?.claims?.fcUserId,
        plan: [plan, ...updatedRecentlyViewPlans],
        expanded,
      }),
    );
  }
};

const removeFromRecentlyViewedPlans = plan => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const recentlyViewedPlans = getRecentlyViewedPlans();
    const expanded = getRecentlyViewExpandedState(finalcadStorageRecentlyViewedPlans);

    const updatedRecentlyViewPlans = recentlyViewedPlans.filter(
      recentlyViewedPlan => recentlyViewedPlan.id !== plan.id,
    );
    if (updatedRecentlyViewPlans.length === 5) updatedRecentlyViewPlans.pop();

    localStorage.setItem(
      finalcadStorageRecentlyViewedPlans,
      JSON.stringify({
        id: oktaToken?.accessToken?.claims?.fcUserId,
        plan: [...updatedRecentlyViewPlans],
        expanded,
      }),
    );
    // This is needed since the local storage event is only triggered
    // if the local storage was changed in another tab/window
    window.dispatchEvent(new Event('storage'));
  }
};

const removeFromRecentlyViewedPlansInsideFolder = folder => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const recentlyViewedPlans = getRecentlyViewedPlans();
    const expanded = getRecentlyViewExpandedState(finalcadStorageRecentlyViewedPlans);

    const updatedRecentlyViewPlans = recentlyViewedPlans.filter(
      recentlyViewedPlan => recentlyViewedPlan.folder_id !== folder.id,
    );
    if (updatedRecentlyViewPlans.length === 5) updatedRecentlyViewPlans.pop();

    localStorage.setItem(
      finalcadStorageRecentlyViewedPlans,
      JSON.stringify({
        id: oktaToken?.accessToken?.claims?.fcUserId,
        plan: [...updatedRecentlyViewPlans],
        expanded,
      }),
    );
    // This is needed since the local storage event is only triggered
    // if the local storage was changed in another tab/window
    window.dispatchEvent(new Event('storage'));
  }
};

const getRecentlyViewedProjects = (businessOrganizationId = null) => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const { id, projects } =
      JSON.parse(localStorage.getItem(finalcadStorageRecentlyViewedProjects)) || {};
    if (id !== oktaToken?.accessToken?.claims?.fcUserId) {
      localStorage.setItem(
        finalcadStorageRecentlyViewedProjects,
        JSON.stringify({
          id: oktaToken?.accessToken?.claims?.fcUserId,
          project: [],
        }),
      );
      return [];
    }
    let filteredProject = projects;
    if (businessOrganizationId && projects)
      filteredProject = projects.filter(
        project => project.business_organization_id === businessOrganizationId,
      );
    return filteredProject || [];
  }
  return [];
};

const addRecentlyViewedProjects = project => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const recentlyViewedProjects = getRecentlyViewedProjects();
    const expanded = getRecentlyViewExpandedState(finalcadStorageRecentlyViewedProjects);

    const updatedRecentlyViewProjects = recentlyViewedProjects.filter(
      recentlyViewedProject => recentlyViewedProject.project_id !== project.project_id,
    );

    if (
      updatedRecentlyViewProjects.filter(
        recentlyViewedProject =>
          recentlyViewedProject.business_organization_id === project.business_organization_id,
      ).length === 5
    )
      updatedRecentlyViewProjects.pop();

    localStorage.setItem(
      finalcadStorageRecentlyViewedProjects,
      JSON.stringify({
        id: oktaToken?.accessToken?.claims?.fcUserId,
        projects: [project, ...updatedRecentlyViewProjects],
        expanded,
      }),
    );
  }
};

const updateRecentlyViewedProjects = projects => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const recentlyViewedProjects = getRecentlyViewedProjects();
    const expanded = getRecentlyViewExpandedState(finalcadStorageRecentlyViewedProjects);
    const remainingRecently = recentlyViewedProjects.filter(el =>
      projects.some(item => item.project_id !== el.project_id),
    );
    projects.forEach(project => {
      const projectIndex = remainingRecently.findIndex(
        recentlyViewedProject => recentlyViewedProject.project_id === project.project_id,
      );
      if (projectIndex > -1) {
        remainingRecently[projectIndex] = project;
        localStorage.setItem(
          finalcadStorageRecentlyViewedProjects,
          JSON.stringify({
            id: oktaToken?.accessToken?.claims?.fcUserId,
            projects: remainingRecently,
            expanded,
          }),
        );
      }
    });
  }
};

const removeFromRecentlyViewedProjects = project => {
  const oktaToken = getOktaTokenStorage();
  if (oktaToken?.accessToken) {
    const recentlyViewedProjects = getRecentlyViewedProjects();
    const expanded = getRecentlyViewExpandedState(finalcadStorageRecentlyViewedProjects);

    const updatedRecentlyViewProjects = recentlyViewedProjects.filter(
      recentlyViewedProject => recentlyViewedProject.project_id !== project.project_id,
    );
    if (updatedRecentlyViewProjects.length === 5) updatedRecentlyViewProjects.pop();

    localStorage.setItem(
      finalcadStorageRecentlyViewedProjects,
      JSON.stringify({
        id: oktaToken?.accessToken?.claims?.fcUserId,
        projects: [...updatedRecentlyViewProjects],
        expanded,
      }),
    );
    // This is needed since the local storage event is only triggered
    // if the local storage was changed in another tab/window
    window.dispatchEvent(new Event('storage'));
  }
};

const getRecentlyViewExpandedState = key => {
  let storageByKey = localStorage.getItem(key);
  try {
    storageByKey = storageByKey ? JSON.parse(storageByKey) : null;
  } catch (e) {
    storageByKey = null;
  }
  return storageByKey?.expanded;
};

const setRecentlyViewExpandedState = (key, expanded) => {
  const data = JSON.parse(localStorage.getItem(key)) || {};
  localStorage.setItem(key, JSON.stringify({ ...data, expanded }));
};

const addRecentlyViewedOrganization = (userId, organization) => {
  const recentOrganizations =
    JSON.parse(localStorage.getItem(finalcadStorageRecentlyViewedOrganizations)) || {};

  recentOrganizations[`${userId}`] = organization;
  localStorage.setItem(
    finalcadStorageRecentlyViewedOrganizations,
    JSON.stringify(recentOrganizations),
  );
};

const getRecentlyViewedOrganization = userId => {
  const recentOrganizations =
    JSON.parse(localStorage.getItem(finalcadStorageRecentlyViewedOrganizations)) || {};

  return recentOrganizations[`${userId}`];
};

const setUserInUserStore = ({ id, user }) => {
  const userStore = JSON.parse(localStorage.getItem(finalcadUserStore)) || {};
  if (!userStore) {
    localStorage.setItem(
      finalcadUserStore,
      JSON.stringify({
        [id]: user,
      }),
    );
    return;
  }
  localStorage.setItem(finalcadUserStore, JSON.stringify({ ...userStore, [id]: user }));
};

const getUserFromUserStore = userId => {
  const userStore = JSON.parse(localStorage.getItem(finalcadUserStore)) || {};

  return userStore[`${userId}`];
};

const setUserStore = userStore =>
  localStorage.setItem(finalcadUserStore, JSON.stringify(userStore));

const getUserStore = () => JSON.parse(localStorage.getItem(finalcadUserStore)) || {};

const removeOktaTokenStorage = () => {
  localStorage.removeItem(oktaTokenStorage);
  return localStorage.removeItem(oktaCacheStorage);
};

const setOktaTokenStorage = token => localStorage.setItem(oktaTokenStorage, JSON.stringify(token));

const getFirebaseFeatureStorage = () =>
  JSON.parse(localStorage.getItem(firebaseFeatureStorage)) || null;

const setFirebaseFeatureStorage = params =>
  localStorage.setItem(firebaseFeatureStorage, JSON.stringify(params));

const setGroupInGroupsStore = ({ id, group }) => {
  const groupsStore = JSON.parse(localStorage.getItem(finalcadGroupsStorage)) || {};
  if (!groupsStore) {
    localStorage.setItem(
      finalcadGroupsStorage,
      JSON.stringify({
        [id]: group,
      }),
    );
    return;
  }
  localStorage.setItem(finalcadGroupsStorage, JSON.stringify({ ...groupsStore, [id]: group }));
};
const getGroupFromGroupsStore = groupId => {
  const groupStore = JSON.parse(localStorage.getItem(finalcadGroupsStorage)) || {};

  return groupStore[`${groupId}`];
};

export const useLastVisitedPage = () => {
  const oktaToken = getOktaTokenStorage();

  const [value, setValue] = React.useState(
    JSON.parse(
      localStorage.getItem(finalcadLastVisitedPage + oktaToken?.accessToken?.claims?.fcUserId),
    ) ?? '/welcome',
  );

  React.useEffect(
    () => {
      localStorage.setItem(
        finalcadLastVisitedPage + oktaToken?.accessToken?.claims?.fcUserId,
        JSON.stringify(value),
      );
    },
    [value],
  );

  return [value, setValue];
};

const saveFCTableConfiguration = (id, config) => {
  const userId = getCurrentUserId();
  const fcTableKey = `FCTableConfig_${userId}_${id}`;
  localStorage.setItem(fcTableKey, JSON.stringify(config));
};

const getFCTableConfiguration = id => {
  const userId = getCurrentUserId();
  const fcTableKey = `FCTableConfig_${userId}_${id}`;
  return JSON.parse(localStorage.getItem(fcTableKey));
};

export default {
  setUserInUserStore,
  getUserFromUserStore,
  setUserStore,
  getUserStore,
  getBusinessOrganizationLocalStorage,
  setBusinessOrganizationLocalStorage,
  removeBusinessFromLocalStorage,
  setLanguageUserStorage,
  getLanguageUserFromLocalStorage,
  removeFromRecentlyViewedPlans,
  removeFromRecentlyViewedPlansInsideFolder,
  getRecentlyViewedPlans,
  addRecentlyViewedPlans,
  updateRecentlyViewedPlans,
  getRecentlyViewedProjects,
  addRecentlyViewedProjects,
  updateRecentlyViewedProjects,
  removeFromRecentlyViewedProjects,
  setEmailVerificationLink,
  getEmailVerificationLink,
  setEmailValidationLocation,
  getEmailValidationLocation,
  getRecentlyViewExpandedState,
  setRecentlyViewExpandedState,
  addRecentlyViewedOrganization,
  getRecentlyViewedOrganization,
  removeOktaTokenStorage,
  getOktaTokenStorage,
  setOktaTokenStorage,
  setFirebaseFeatureStorage,
  getFirebaseFeatureStorage,
  setLanguageTradeStorage,
  getLanguageTradeLocalStorage,
  setFinalcadCurrentOffer,
  getFinalcadCurrentOffer,
  setGroupInGroupsStore,
  getGroupFromGroupsStore,
  getThemePreference,
  setThemePreference,
  cleanExpiredProjectFilter,
  saveFilterByProjectAndModule,
  getFilterByProjectAndModule,
  clearFilterByProjectAndModule,
  saveFCTableConfiguration,
  getFCTableConfiguration,
  saveReportCustomization,
  getReportCustomization,
  setTmpNavigationItems,
  getTmpNavigationItems,
  saveProjectsListFilters,
  getProjectsListFilters,
};
