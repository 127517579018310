import React, { useContext } from 'react';

import { Box, styled } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';

import Button from 'components/Button';
import ArrowRight from 'components/SvgComponents/icons/ArrowRight';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';
import { authorizations } from 'utils/constants';

export const styles = theme => ({
  rootContent: {
    padding: `0 !important`,
  },
  title: {
    color: theme.palette.secondary.main,
  },
  subTitle: {
    marginTop: theme.spacing(1),
  },
});

const Box_Bannerwrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'fullWidth',
})(({ theme, fullWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 16,
  gap: '0.5em',
  boxShadow: 'none',
  padding: '1rem 1.5rem',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.light,
  width: fullWidth ? '100%' : 800,
}));

export function OverSeatOfferBanner({ classes, intl, currentPlan, fullWidth, blockItemsCreation }) {
  const businessOrganization = useContext(OrganizationContext);
  const history = useHistory();

  const countSeat = businessOrganization?.used_licenses_count || 0;
  const countTotalSeat = currentPlan?.total_user_plan || 0;
  const overSeat = countSeat - countTotalSeat > 0 ? countSeat - countTotalSeat : 0;
  const canWriteOffer =
    businessOrganization?.authorizations?.indexOf(authorizations.organization.writeOffer) > -1;

  const onUpgradeOfferClick = () => {
    history.push(Routing.organizations.contract(businessOrganization?.id));
  };

  const getDescription = () => {
    if (blockItemsCreation) {
      return (
        <>
          {canWriteOffer
            ? intl.formatMessage(globalMessages.items_limit_reached_description_admin)
            : intl.formatMessage(globalMessages.items_limit_reached_description_user)}
        </>
      );
    }
    return (
      <>
        {intl.formatMessage(
          canWriteOffer
            ? globalMessages.reach_organization_seat_limit_description_admin
            : globalMessages.reach_organization_seat_limit_description_user,
          {
            overSeat,
            countSeat,
            countTotalSeat,
          },
        )}
      </>
    );
  };

  return (
    <Box_Bannerwrapper fullWidth>
      <CardContent
        classes={{
          root: classes.rootContent,
        }}
      >
        <Typography
          component="p"
          variant="subtitleLight"
          classes={{
            root: classes.title,
          }}
        >
          {intl.formatMessage(globalMessages.reach_organization_seat_limit_title)}
        </Typography>
        <Typography
          component="p"
          variant="subtitleLightBold"
          classes={{
            root: classes.subTitle,
          }}
        >
          {getDescription()}
        </Typography>
      </CardContent>
      {canWriteOffer && (
        <Button
          onClick={onUpgradeOfferClick}
          startIcon={<ArrowRight />}
          classes={{
            root: classes.rootButton,
          }}
          variant="default"
        >
          {intl.formatMessage(globalMessages.upgrade_offer_button_title)}
        </Button>
      )}
    </Box_Bannerwrapper>
  );
}

OverSeatOfferBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  currentPlan: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  blockItemsCreation: PropTypes.bool,
};

OverSeatOfferBanner.defaultProps = {};

export default compose(
  injectIntl,
  withStyles(styles),
)(OverSeatOfferBanner);
