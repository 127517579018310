import React, { useCallback, useEffect, useState } from 'react';

import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import ActionDropdown from 'components/ActionDropdown';
import ChevronDown from 'components/SvgComponents/icons/ChevronDown';
import ChevronUp from 'components/SvgComponents/icons/ChevronUp';
import combineStyles from 'theme/combineStyles';
import tableActionsStyles from 'theme/table-actions-theme';

import { getUserWorkspaces, setSelectedWorkSpaces, setUserWorkSpaces } from './actions';
import {
  selectLoadingFlag,
  selectSelectedWorkspace,
  selectUserWorkspacesByParent,
} from './selectors';
import WorkspaceSelectList from './WorkspaceSelectList';

export const styles = theme => ({
  iconWrap: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: 2,
  },
});

export const SwitcherSelect = ({
  classes,
  disabled,
  loading,
  workspaces,
  selectedWorkspace,
  dispatchSetSelectedWorkSpaces,
  dispatchGetUserWorkspaces,
  dispatchSetUserWorkSpaces,
  placement,
}) => {
  const [displayMenu, setDisplayMenu] = useState('');
  const [checkedWorkspace, setCheckedWorkspace] = useState({});
  const [searchText, setSearchText] = useState('');
  const { organizationId } = useParams();

  useEffect(() => () => dispatchSetUserWorkSpaces([]), [dispatchSetUserWorkSpaces]);

  const onToggleMenu = () => {
    setDisplayMenu(!displayMenu);
    if (displayMenu) {
      setSearchText('');
    }
    dispatchSetSelectedWorkSpaces({ id: 'root' });
    dispatchSetUserWorkSpaces([]);
  };
  const handleCloseSwitcher = () => {
    setDisplayMenu(false);
    setSearchText('');
  };

  const handleSelected = workSpace => {
    dispatchSetSelectedWorkSpaces(workSpace);
  };

  const handleChecked = workSpace => {
    setCheckedWorkspace(workSpace);
  };
  const handleSearch = text => {
    setSearchText(text);
    getUserWorkSpaces(text);
  };

  const getUserWorkSpaces = useCallback(
    debounce(text => {
      if (!text) {
        dispatchSetUserWorkSpaces([]);
      }
      dispatchGetUserWorkspaces(organizationId, selectedWorkspace.id, text);
    }, 500),
    [selectedWorkspace.id],
  );

  const renderLabel = () =>
    displayMenu ? (
      <ChevronUp width={16} height={16} className={classes.iconWrap} />
    ) : (
      <ChevronDown width={16} height={16} className={classes.iconWrap} />
    );

  return (
    <ActionDropdown
      onToggleMenu={onToggleMenu}
      component={IconButton}
      disabled={disabled}
      open={!!displayMenu}
      loading={loading}
      name="switcher"
      label={renderLabel()}
      anchorOrigin={{
        vertical: 30,
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      placement={placement}
    >
      <WorkspaceSelectList
        checkedWorkspace={checkedWorkspace}
        workspaces={workspaces}
        loading={loading}
        searchText={searchText}
        onGetUserWorkSpaces={dispatchGetUserWorkspaces}
        selectedWorkspace={selectedWorkspace}
        onSelect={handleSelected}
        onClose={handleCloseSwitcher}
        onChecked={handleChecked}
        onSearch={handleSearch}
      />
    </ActionDropdown>
  );
};

SwitcherSelect.propTypes = {
  loading: PropTypes.bool,
  workspaces: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  selectedWorkspace: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dispatchGetUserWorkspaces: PropTypes.func,
  dispatchSetSelectedWorkSpaces: PropTypes.func,
  dispatchSetUserWorkSpaces: PropTypes.func,
  placement: PropTypes.string,
};

const mapDispatchToProps = {
  dispatchGetUserWorkspaces: getUserWorkspaces,
  dispatchSetSelectedWorkSpaces: setSelectedWorkSpaces,
  dispatchSetUserWorkSpaces: setUserWorkSpaces,
};
const mapStateToProps = createStructuredSelector({
  workspaces: selectUserWorkspacesByParent(),
  selectedWorkspace: selectSelectedWorkspace(),
  loading: selectLoadingFlag(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withRouter,
  withStyles(combineStyles(tableActionsStyles, styles)),
)(SwitcherSelect);
