import {
  GET_RECIPIENTS,
  SET_RECIPIENTS,
  SET_RECIPIENTS_PREFERENCES,
  SET_LOADING_FLAG,
  CLEAR_ACTIVE_FILTERS,
  SET_ACTIVE_FILTERS,
  GET_FORMS_PRIORITIES,
  SET_FORMS_PRIORITIES,
  GET_OBSERVATIONS_PRIORITIES,
  SET_OBSERVATIONS_PRIORITIES,
  SET_PERIODIC_PREFERENCES,
  CLEAR_PERIODIC_PREFERENCES,
  SCHEDULE_REPORT,
  GET_REPORTS_LIST,
  DELETE_REPORT,
  SET_CUSTOMIZATION_CONFIGS,
  CLEAR_CUSTOMIZATION_CONFIGS,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function getFormsPriorities({ params }) {
  return {
    type: GET_FORMS_PRIORITIES,
    params,
  };
}

export function setFormsPriorities(priorities) {
  return {
    type: SET_FORMS_PRIORITIES,
    priorities,
  };
}
export function getObservationsPriorities() {
  return {
    type: GET_OBSERVATIONS_PRIORITIES,
  };
}

export function setObservationsPriorities(priorities) {
  return {
    type: SET_OBSERVATIONS_PRIORITIES,
    priorities,
  };
}

export function getRecipients({ params }) {
  return {
    type: GET_RECIPIENTS,
    params,
  };
}

export function setRecipients(recipients) {
  return {
    type: SET_RECIPIENTS,
    recipients,
  };
}

export function setRecipientsPreferences(recipient_ids) {
  return {
    type: SET_RECIPIENTS_PREFERENCES,
    recipient_ids,
  };
}

export function setActiveFilters(filters) {
  return {
    type: SET_ACTIVE_FILTERS,
    filters,
  };
}

export function scheduleReport(callback, listsRequestOption) {
  return {
    type: SCHEDULE_REPORT,
    callback,
    listsRequestOption,
  };
}

export function setPeriodicReportPreferences(periodicReportPreferences) {
  return {
    type: SET_PERIODIC_PREFERENCES,
    periodicReportPreferences,
  };
}
export function clearPeriodicReportPreferences() {
  return {
    type: CLEAR_PERIODIC_PREFERENCES,
  };
}

export function clearActiveFilters() {
  return {
    type: CLEAR_ACTIVE_FILTERS,
  };
}

export function getReportsList() {
  return {
    type: GET_REPORTS_LIST,
  };
}

export function deleteReport(reportId, listRequestOption, callback) {
  return { type: DELETE_REPORT, reportId, listRequestOption, callback };
}

export function setCustomizationConfigs(configs) {
  return {
    type: SET_CUSTOMIZATION_CONFIGS,
    configs,
  };
}

export function clearCustomizationConfigs() {
  return {
    type: CLEAR_CUSTOMIZATION_CONFIGS,
  };
}
