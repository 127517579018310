import isEmpty from 'lodash/isEmpty';
import { delay } from 'redux-saga';
import { call, all, put, select } from 'redux-saga/effects';
import SparkMD5 from 'spark-md5';
import uuid from 'uuid';

import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { fileChunkSize, nrOfConcurentMediaRequests } from 'utils/constants';
import { mimeTypesArray } from 'utils/file';
import request from 'utils/request';

let concurentMediaRequests = 0;

const addFromDataMediaResource = (route, item, offset, chunkSize, chunk) => {
  const formData = new FormData();
  formData.append('id', item.id);
  formData.append('order', 1);
  formData.append('range', `bytes ${offset}-${offset + chunkSize - 1}/${item.resources.size}`);
  formData.append('data', chunk);
  if (route.itemId) {
    formData.append('folder_id', route.itemId);
  }
  formData.append('name', item.name);
  formData.append('mime_type', 'bim');
  return formData;
};

export function* uploadBimChunks(item) {
  const chunkSize = fileChunkSize;
  let offset = 0;
  const chunkArray = window.chunkBlobStore[item.resources.uuidBlobFile];
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.1/projects/${route.idData}/plans/bim_upload`;

  for (let i = 0; i < chunkArray.length; i += 1) {
    const { chunk } = chunkArray[i];
    const newSize =
      offset + chunkSize > item.resources.size ? item.resources.size - offset : chunkSize;
    const formData = addFromDataMediaResource(route, item, offset, newSize, chunk);
    const options = {
      method: 'PUT',
      headers: {
        'Cache-Control': 'No-Store',
      },
      body: formData,
    };
    const data = yield call(request, requestURL, options);
    if (!isEmpty(data)) {
      return data;
    }
    offset += newSize;
  }
  return true;
}

const getFileType = fileName => {
  const type = fileName.split('.').pop();
  const mimeTypeEntry = mimeTypesArray.find(entry => entry.ext === type);
  return mimeTypeEntry ? mimeTypeEntry.type : '';
};

export const generateChunks = ({
  file,
  filesUpload,
  filesToUpload,
  setFiles,
  setReject,
  onDragNDrop,
  clearUpload,
  onUploading,
  setLoading,
  onError,
  setPreview,
  setFilesExternal,
  onRemoteSubmit,
  shouldResetFilesAfterDrop,
  singleFileSelection,
}) => {
  let hash;
  const chunkSize = fileChunkSize;
  const spark = new SparkMD5.ArrayBuffer();
  const chunks = Math.ceil(file.size / chunkSize);
  let currentChunk = 0;
  const chunkArray = [];

  const onloadFn = e => {
    spark.append(e.target.result); // append array buffer

    const localSpark = new SparkMD5.ArrayBuffer();
    localSpark.append(e.target.result);
    chunkArray[currentChunk].hash = localSpark.end();
    currentChunk += 1;
    if (currentChunk < chunks) {
      loadNext();
    } else {
      hash = spark.end();
      const uuidBlob = uuid.v4();

      // we read the file again but as base64 because conversion of ArrayBuffer to Base64 is not accurate
      // see Unicode problem https://developer.mozilla.org/en-US/docs/Glossary/Base64#Solution_2_%E2%80%93_rewrite_the_DOMs_atob()_and_btoa()_using_JavaScript's_TypedArrays_and_UTF-8
      const baseReader = new FileReader();
      baseReader.onloadend = event => {
        window.blobStore[uuidBlob] = event.target.result;
        window.chunkBlobStore[uuidBlob] = chunkArray;
        const previewObjUrl = URL.createObjectURL(file);
        filesUpload.push({
          uuidBlobFile: uuidBlob,
          resultedBlob: event.target.result,
          name: file.name,
          initialName: file.name || 'application/acad',
          type: getFileType(file.name),
          size: file.size,
          preview: previewObjUrl,
          hash,
          file,
        });

        if (filesUpload?.length === filesToUpload?.length) {
          if (setFiles) {
            if (singleFileSelection) {
              setFiles(filesUpload);
            } else {
              setFiles(prevStateFiles => [...prevStateFiles, ...filesUpload]);
            }
          }
          if (setFilesExternal) {
            setFilesExternal(filesUpload);
          }
          if (setReject) {
            setReject(false);
          }
          if (setPreview) {
            setPreview(previewObjUrl);
          }
          if (onDragNDrop) {
            onDragNDrop(filesUpload);
            if (shouldResetFilesAfterDrop) {
              clearUpload();
            }
          }
          if (onUploading) {
            onUploading(false);
          }
          if (onRemoteSubmit) {
            onRemoteSubmit();
          }
          if (setLoading) {
            setLoading(false);
          }
        }
      };
      if (file.name.endsWith('.ifc')) {
        baseReader.readAsText(file);
      } else {
        baseReader.readAsDataURL(file);
      }
    }
  };

  const onerrorFn = () => {
    if (onError) {
      onError([file]);
    }
  };

  const loadNext = () => {
    const fileReader = new FileReader();
    fileReader.onload = onloadFn;
    fileReader.onerror = onerrorFn;
    const start = currentChunk * chunkSize;
    const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
    const indexChunk = file.slice(start, end);
    chunkArray.push({ chunk: indexChunk });
    if (file.name.endsWith('.ifc')) {
      fileReader.readAsText(indexChunk);
    } else {
      fileReader.readAsArrayBuffer(indexChunk);
    }
  };
  loadNext();
};

export function* abortMediaUpload(baseUrl, mediaId, chunkId) {
  const requestURL = `${baseUrl}medias/${mediaId}/abort-upload`;
  const payload = {
    chunk_id: chunkId,
  };
  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  yield call(request, requestURL, options);
}

export function* finalizeMediaUpload(baseUrl, mediaId, chunkId) {
  const requestURL = `${baseUrl}medias/${mediaId}/finalize-upload`;
  const payload = {
    chunk_id: chunkId,
  };
  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  return yield call(request, requestURL, options);
}

const constructInitialPayload = item => {
  const options = {};
  options.total_bytes = item.size;
  options.mime_type = item.type;
  options.filename = item.name;
  options.md5 = item.hash;
  if (item.media_id) {
    options.media_id = item.media_id;
  }
  if (item.previous_media_id) {
    options.previous_media_id = item.previous_media_id;
  }
  return JSON.stringify(options);
};

const constructFormDataBody = (chunk, hash, initReqData, index, isLastChunk) => {
  const formData = new FormData();
  formData.append('segment_index', index + 1);
  formData.append('chunk_id', initReqData.chunk_id);
  formData.append('stream', chunk);
  formData.append('md5', hash);
  formData.append('last_chunk', isLastChunk);
  return formData;
};

const constructInitialRequest = (item, baseUrl, headers) => {
  const requestURL = `${baseUrl}medias/init-upload`;
  const payload = constructInitialPayload(item);
  const options = {
    method: 'POST',
    headers: headers || {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: payload,
  };
  return call(request, requestURL, options);
};

const constructSubsequentRequests = (baseUrl, chunkObj, initReqData, index, isLastChunk) => {
  const requestURL = `${baseUrl}medias/${initReqData.media_id}/append-upload`;
  const formData = constructFormDataBody(
    chunkObj.chunk,
    chunkObj.hash,
    initReqData,
    index,
    isLastChunk,
  );
  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
    },
    body: formData,
  };
  return call(request, requestURL, options);
};

export function* uploadChunks(
  item,
  options = { baseUrl: '', parallel: false, requestHeaders: null },
) {
  const { baseUrl, parallel, requestHeaders } = options;

  const chunkSize = fileChunkSize;
  let offset = 0;
  let newSize = offset + chunkSize > item.size ? item.size - offset : chunkSize;
  const chunkArray = window.chunkBlobStore[item.uuidBlobFile];

  // make first request to get mediaId
  const initReqData = yield constructInitialRequest(item, baseUrl, requestHeaders);

  // push in the list all other requests except last
  const afterInitialRequestList = [];
  for (let index = 0; index < chunkArray.length; index += 1) {
    const chunkContainer = chunkArray[index];
    const isLastChunk = index === chunkArray.length - 1;
    afterInitialRequestList.push(
      constructSubsequentRequests(baseUrl, chunkContainer, initReqData, index, isLastChunk),
    );
    newSize = offset + chunkSize > item.size ? item.size - offset : chunkSize;
    offset += newSize;
  }

  let data;
  if (parallel) {
    data = yield all(afterInitialRequestList);
  } else {
    let wasAborted = false;
    for (let i = 0; i < afterInitialRequestList.length; i += 1) {
      if (!wasAborted) {
        const initData = yield afterInitialRequestList[i];
        if (initData && initData.message) {
          yield put(setGlobalError(initData));
          if (!wasAborted) {
            yield call(abortMediaUpload, baseUrl, initReqData.media_id, initReqData.chunk_id);
          }
          wasAborted = true;
        }
      }
    }
    if (!wasAborted) {
      data = yield call(finalizeMediaUpload, baseUrl, initReqData.media_id, initReqData.chunk_id);
    }
  }
  return data;
}

export function* uploadDirect(
  item,
  options = { baseUrl: '', parallel: false, requestHeaders: null },
) {
  const { baseUrl } = options;
  // since the direct upload is under 5MB this means that the only chunk in the chunk array is the whole streamable file
  const blob = item?.newBlob || window.chunkBlobStore[item.uuidBlobFile][0].chunk;

  const requestURL = `${baseUrl}medias/uploads`;
  const mediaFormData = new FormData();
  mediaFormData.append('mime_type', getFileType(item.name));
  mediaFormData.append('md5', item.hash);
  mediaFormData.append('size', item.size);
  mediaFormData.append('stream', blob);

  if (item.previous_media_id) {
    mediaFormData.append('previous_media_id', item.previous_media_id);
  }
  mediaFormData.append('file_name', item.name || item.file_name);
  const reqOptions = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
    },
    body: mediaFormData,
  };
  const data = yield call(request, requestURL, reqOptions);

  return data;
}

export function* uploadMedia(
  item,
  options = { baseUrl: '', parallel: false, requestHeaders: null },
) {
  let requestCall;
  while (concurentMediaRequests > nrOfConcurentMediaRequests) {
    yield delay(200);
  }
  if (item.size > fileChunkSize) {
    requestCall = uploadChunks;
  } else {
    requestCall = uploadDirect;
  }
  concurentMediaRequests += 1;
  const data = yield requestCall(item, options);
  concurentMediaRequests -= 1;

  return data;
}
