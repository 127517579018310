import React, { useEffect, useState } from 'react';

import {
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuList,
  Radio,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Button from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';
import RemoveScrollbar from 'components/RemoveScrollbar';
import SearchTextField from 'components/SearchTextFieldV3';
import ArrowLeft from 'components/SvgComponents/icons/ArrowLeft';
import ChevronRight from 'components/SvgComponents/icons/ChevronRight';
import CircleCheckEmpty from 'components/SvgComponents/icons/CircleCheckEmpty';
import CircleRadio from 'components/SvgComponents/icons/CircleRadio';
import GridSvg from 'components/SvgComponents/icons/Grid';
import Undo from 'components/SvgComponents/icons/Undo';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import Tooltip from 'components/Tooltip';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { CustomNavLink } from 'utils/redirect-utils';

const styles = theme => ({
  workspaceSelectList: {
    maxHeight: 305,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: 100,
  },
  listItemIcon: {
    minWidth: 24,
  },
  workspaceNotFound: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    height: 100,
  },
  workspaceOptions: {
    display: 'flex',
    flexDirection: 'column',
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  workspacePadding: {
    paddingBottom: theme.spacing(3),
  },
  headerList: {
    marginTop: theme.spacing(),
  },
  hasChildrenIcon: {
    color: theme.palette.text.secondary,
    marginLeft: 'auto',
  },
  minHeightMenu: {
    minHeight: 35,
  },
  chevronRightSpace: {
    marginLeft: 'auto',
  },
  loadingWrap: {
    minHeight: 80,
  },
  applyBtn: {
    textTransform: 'inherit',
    marginLeft: 'auto',
  },
  gotoOrganization: {
    textTransform: 'inherit',
    alignItems: 'center',
    display: 'flex',
    textDecoration: 'none',
    fontSize: '0.75rem',
    color: theme.palette.text.primary,
    '& svg': {
      marginRight: theme.spacing(),
    },
  },
  heightContainerItems: {
    height: 140,
    maxWidth: 400,
    overflow: 'auto',
  },
  typographyEllipsisWidth: {
    maxWidth: '300px',
  },
});

/**
 * MenuList
 */
function WorkspaceSelectList({
  selectedWorkspace,
  onGetUserWorkSpaces,
  workspaces,
  classes,
  searchText,
  checkedWorkspace,
  intl,
  loading,
  match,
  history,
  onSelect,
  onChecked,
  onSearch,
  onClose,
  renderApplyButton,
  emptyOptionRerenderer,
}) {
  const [parentTree, setParentTree] = useState([]);
  const { id, name } = selectedWorkspace || {};
  let isEmpty = !loading && !workspaces?.length && searchText;
  const renderEmpty = emptyOptionRerenderer && emptyOptionRerenderer();
  if (renderEmpty) {
    isEmpty = false;
  }
  const optionNameRefs = React.useRef([]);
  optionNameRefs.current = workspaces.map(
    (element, i) => optionNameRefs.current[i] ?? React.createRef(),
  );

  const { organizationId, workspaceId } = match.params || {};

  useEffect(
    () => {
      onGetUserWorkSpaces(organizationId, id, searchText);
    },
    [id, onGetUserWorkSpaces],
  );

  useEffect(() => {
    Analytics.track('workspace_switcher_opened');
  }, []);

  const handleBackClick = () => {
    onSelect(parentTree[0]);
    setParentTree(prevState => [...prevState.slice(1)]);
  };

  const handleClick = option => () => {
    setParentTree(prevState => [selectedWorkspace, ...prevState]);
    onSelect(option);
  };

  const handleChange = option => () => {
    onChecked(option);
  };

  const handleApply = () => {
    onClose();
    const newWorkspaceId = checkedWorkspace?.id;
    const newPath = Routing.replaceWorkspaceId(history.location.pathname, newWorkspaceId);
    history.push(newPath);
  };

  const renderHeader = () => {
    if (!id || id === 'root') {
      return (
        <ListItem className={classes.headerList} divider>
          <ListItemIcon className={classes.listItemIcon}>
            <GridSvg
              width={16}
              height={16}
              title={intl.formatMessage(globalMessages.change_workspace)}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography component="p" variant="body">
              {intl.formatMessage(globalMessages.change_workspace)}
            </Typography>
          </ListItemText>
        </ListItem>
      );
    }

    return (
      <ListItem onClick={handleBackClick} className={classes.minHeightMenu} button divider>
        <ListItemIcon className={classes.listItemIcon}>
          <ArrowLeft width={16} height={16} title={name} />
        </ListItemIcon>
        <ListItemText>
          <TypographyEllipsis
            className={classes.typographyEllipsisWidth}
            component="p"
            variant="body"
          >
            {name}
          </TypographyEllipsis>
        </ListItemText>
      </ListItem>
    );
  };

  const renderFooter = () => (
    <ListItem>
      {workspaceId && (
        <Link
          component={CustomNavLink}
          to={`/organizations/${organizationId}/info`}
          className={classes.gotoOrganization}
        >
          <Undo width="14" height="14" color="inherit" />
          {intl.formatMessage(globalMessages.organization_settings)}
        </Link>
      )}
      <Button
        disabled={!checkedWorkspace?.id}
        onClick={handleApply}
        variant="text"
        className={classes.applyBtn}
      >
        {intl.formatMessage(globalMessages.apply)}
      </Button>
    </ListItem>
  );

  const renderOptions = () => {
    if (isEmpty) {
      return (
        <div className={classes.workspaceNotFound}>
          <Typography component="p" variant="bodyLight">
            {intl.formatMessage(globalMessages.no_result_found)}
          </Typography>
        </div>
      );
    }
    return (
      <MenuList className={classes.workspaceOptions} variant="menu">
        {(workspaces || []).map((option, index) => (
          <ListItem dense key={option.id}>
            <ListItemIcon className={classes.listItemIcon}>
              <Radio
                onChange={handleChange(option)}
                checked={checkedWorkspace.id === option?.id}
                color="secondary"
                icon={<CircleCheckEmpty width={16} height={16} />}
                checkedIcon={<CircleRadio width={16} height={16} />}
              />
            </ListItemIcon>
            <ListItemText>
              <Tooltip
                title={option.name}
                placement="right"
                key={`tooltip-${option.id}`}
                forwardedRef={optionNameRefs.current[index]}
              >
                <TypographyEllipsis
                  className={classes.typographyEllipsisWidth}
                  component="p"
                  variant="body"
                  ref={optionNameRefs.current[index]}
                >
                  {option.name}
                </TypographyEllipsis>
              </Tooltip>
            </ListItemText>
            {option?.has_children && (
              <IconButton onClick={handleClick(option)} className={classes.chevronRightSpace}>
                <ChevronRight width={16} height={16} className={classes.hasChildrenIcon} />
              </IconButton>
            )}
          </ListItem>
        ))}
        {renderEmpty}
      </MenuList>
    );
  };

  const renderLayout = () => {
    if (!workspaces.length && !searchText && !loading) {
      return (
        <div className={classes.workspaceNotFound}>
          <Typography component="p" variant="bodyLight" gutterBottom>
            <GridSvg
              width={24}
              height={24}
              title={intl.formatMessage(globalMessages.change_workspace)}
              color="inherit"
            />
          </Typography>
          <Typography component="p" variant="bodyLight">
            {intl.formatMessage(globalMessages.workspace_switch_empty_view)}
          </Typography>
        </div>
      );
    }

    return (
      <>
        <ListItem>
          <SearchTextField
            size="small"
            searchText={searchText}
            onChange={onSearch}
            searchInputProps={{
              placeholder: intl.formatMessage(globalMessages.search),
              autoFocus: true,
            }}
          />
        </ListItem>
        <div className={classes.heightContainerItems}>
          <RemoveScrollbar>
            {loading && (
              <div className={classes.loadingWrap}>
                <LoadingIndicator size="40" withBackground={false} />
              </div>
            )}
            {renderOptions()}
          </RemoveScrollbar>
        </div>
        {renderApplyButton && (
          <>
            <Divider />
            {renderFooter()}
          </>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames({
        [classes.workspaceSelectList]: true,
        [classes.workspacePadding]: !renderApplyButton,
      })}
    >
      {renderHeader()}
      {renderLayout()}
    </div>
  );
}

WorkspaceSelectList.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object,
  searchText: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  checkedWorkspace: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  workspaces: PropTypes.arrayOf(PropTypes.object),
  selectedWorkspace: PropTypes.object,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  onGetUserWorkSpaces: PropTypes.func,
  renderApplyButton: PropTypes.bool,
  onChecked: PropTypes.func,
  onSearch: PropTypes.func,
  emptyOptionRerenderer: PropTypes.func,
};
WorkspaceSelectList.defaultProps = {
  renderApplyButton: true,
};

export default compose(
  injectIntl,
  withRouter,
  withStyles(styles),
)(WorkspaceSelectList);
