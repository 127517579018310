import React from 'react';

import { styled } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import { styles } from 'theme/table-style';

const Div_CellContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Div_IconContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const TextCell = ({
  loading,
  keyValue,
  description,
  textVariant,
  capitalize,
  Icon,
  uppercase,
}) => {
  if (loading && !keyValue) {
    return <TextCellSkeleton />;
  }

  if (!loading && !keyValue) {
    return <div>-</div>;
  }

  const valueStyle = capitalize
    ? { textTransform: 'capitalize' }
    : uppercase
      ? { textTransform: 'uppercase' }
      : {};

  return (
    <Div_CellContainer>
      <Div_IconContainer>
        {Icon}
        <TypographyEllipsis noWrap component="p" variant={textVariant} sx={valueStyle}>
          {keyValue}
        </TypographyEllipsis>
      </Div_IconContainer>
      {description && (
        <TypographyEllipsis noWrap component="p" variant="subtitleLight">
          {description}
        </TypographyEllipsis>
      )}
    </Div_CellContainer>
  );
};

TextCell.propTypes = {
  keyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textVariant: PropTypes.string,
  capitalize: PropTypes.bool,
  loading: PropTypes.bool,
  Icon: PropTypes.elementType,
  uppercase: PropTypes.bool,
};

TextCell.defaultProps = {
  textVariant: 'subtitle',
  keyValue: '',
  description: '',
  capitalize: false,
  loading: false,
  Icon: null,
  uppercase: false,
};

export default withStyles(styles)(TextCell);
