import React from 'react';

import { FormControl, Input, Select as SelectMUI } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import combineStyles from 'theme/combineStyles';
import formEngineStyle from 'theme/formEngine-theme';
import globalMessages from 'translations/messages/global-messages';

export const styles = theme => ({
  root: {
    borderRadius: 12,
    color: theme.palette.text.primary,
  },
  rootSmall: {
    fontSize: '0.875rem',
  },
  rootStandard: {
    fontSize: '1rem',
  },
  selectMenu: {
    backgroundColor: theme.palette.background.primary,
    border: `1px solid ${theme.palette.background.primary}`,
    borderRadius: `12px !important`,
    '&:hover:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.primary,
      borderRadius: 12,
    },
    '&:focus:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.primary,
      borderRadius: 12,
    },
  },
  menuListClass: {
    padding: 0,
  },
  paperClass: {
    marginTop: theme.spacing(),
  },
  small: {
    padding: '0.350em 2.8em 0.350em 1em',
  },
  medium: {
    padding: '0.625em 2.8em 0.625em 1em',
  },
  selectMenuWithIcon: {
    paddingLeft: '2.5em',
  },
  icon: {
    right: 10,
    color: 'inherit',
  },
  customIcon: {
    color: theme.palette.text.primary,
    position: 'absolute',
    zIndex: 1,
    left: '1em',
  },
  customIconEnd: {
    width: 16,
    height: 16,
    right: 10,
    position: 'absolute',
  },
  disabled: {
    border: `1px solid transparent !important`,
  },
});
function Select(props) {
  const {
    children,
    classes,
    placeholder,
    value,
    intl,
    defaultValue,
    renderValue,
    fullWidth,
    MenuProps,
    selectClasses,
    icon,
    size,
    disabled,
    formControlClasses,
    endIcon,
    rootFontSize,
    ...custom
  } = props;

  return (
    <FormControl fullWidth={fullWidth} classes={formControlClasses}>
      <SelectMUI
        {...custom}
        disabled={disabled}
        onChange={props.onChange}
        renderValue={renderValue}
        displayEmpty
        sx={{
          '& .MuiSvgIcon-root': {
            opacity: endIcon ? 0 : 1,
          },
        }}
        value={value || defaultValue || placeholder || intl.formatMessage(globalMessages.select)}
        input={
          <Input
            startAdornment={
              icon && {
                ...icon,
                props: {
                  width: 16,
                  height: 16,
                  className: classes.customIcon,
                },
              }
            }
            endAdornment={
              endIcon && {
                ...endIcon,
                props: {
                  className: classes.customIconEnd,
                },
              }
            }
          />
        }
        disableUnderline
        classes={{
          root: classNames(classes.root, {
            [classes.rootSmall]: rootFontSize === 'small',
            [classes.rootStandard]: !rootFontSize || rootFontSize === 'medium',
          }),
          select: classNames(classes.selectMenu, {
            [classes.selectMenuWithIcon]: icon,
            [classes.small]: size === 'small',
            [classes.medium]: size === 'medium',
          }),
          disabled: classes.disabled,
          icon: classes.icon,
          ...selectClasses,
        }}
        MenuProps={{
          classes: {
            paper: classes.paperClass,
          },
          MenuListProps: {
            classes: {
              root: classes.menuListClass,
            },
          },
          ...MenuProps,
        }}
      >
        {children}
      </SelectMUI>
    </FormControl>
  );
}

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  formControlClasses: PropTypes.object,
  placeholder: PropTypes.string,
  intl: intlShape.isRequired,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.string,
  selectClasses: PropTypes.object,
  disabled: PropTypes.bool,
  MenuProps: PropTypes.object,
  renderValue: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  icon: PropTypes.node,
  endIcon: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium']),
  rootFontSize: PropTypes.oneOf(['small', 'medium']),
};

Select.defaultProps = {
  size: 'medium',
  fullWidth: true,
  disabled: false,
};

export default compose(
  injectIntl,
  withStyles(combineStyles(formEngineStyle, styles)),
)(Select);
