import moment from 'moment';
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import uuid from 'uuid';

import { groupUpdateAction } from 'components/MessageThread/constants';
import config from 'config/config';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { checkParams } from 'utils/errors/check-params';
import localStorageUser from 'utils/localStorageUser';
import { getArrayOrDefault } from 'utils/mappers';
import request from 'utils/request';

import { customerIoTrackerWithParams } from '../../../utils/CustomerIo';
import {
  selectGroup,
  setGroupDetails,
  setGroups,
  setGroupsMembers,
  setLoadingFlag,
  setLoadingGroupDetailsFlag,
  setSearched,
  setProjectMembers,
  getGroupDetails,
} from './actions';
import {
  ADD_GROUP_MEMBERS,
  ADD_NEW_GROUP,
  GET_GROUP_DETAILS,
  GET_GROUPS,
  GET_PROJECT_MEMBERS,
  UPDATE_GROUP,
  groupUpdateActions,
  UPDATE_DISPLAYED_LIST,
} from './constants';
import { selectCurrentGroup, selectGroups, selectGroupDetails } from './selectors';

function* fetchGroupMembers({ projectId, groupId, searchTerm }) {
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${projectId}/groups/${groupId}/members`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      search_term: searchTerm || '',
    }),
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setGroupsMembers(groupId, data.group_members));
  }
}
function* fetchGroups({ searchValue, searched, params }) {
  yield put(setLoadingFlag(true));
  checkParams({ params, keys: ['idData'] });
  yield put(setSearched(searched));
  const search_term = searchValue ? `?search_term=${searchValue}&limit=500` : '?limit=500';
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.4/projects/${params.idData}/groups/search${search_term}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    const sortedGroups = (data.groups || []).sort(
      (a, b) => moment(b.last_message_date).valueOf() - moment(a.last_message_date).valueOf(),
    );
    if (sortedGroups.length && !searched) {
      localStorageUser.setGroupInGroupsStore({
        id: sortedGroups[0]?.group_id,
        group: {
          ...sortedGroups[0],
          message_read_date: sortedGroups[0].last_message_date,
        },
      });
      yield put(selectGroup(sortedGroups[0]));
      yield data.groups.map(group =>
        call(fetchGroupMembers, {
          projectId: params.idData,
          groupId: group?.group_id,
        }),
      );
    }
    yield put(setGroups(sortedGroups || []));
  }
  yield put(setLoadingFlag(false));
}
function* addNewGroup({ organizationId, projectId, groupName }) {
  yield put(setLoadingFlag(true));
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/groups`;
  const body = {
    name: groupName,
    id: uuid.v4(),
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    Analytics.track('groups_creation_success', {
      invite_member: false,
      type: 'private',
    });
    yield customerIoTrackerWithParams('groups_creation_success', organizationId, projectId);
    yield put(setGroupDetails(data));
  } else {
    Analytics.track('groups_creation_failure', {
      failure_reason: 'server_error',
    });
  }
  yield put(setLoadingFlag(false));
}
function* fetchGroupDetails({ params, groupId, setLoader = true, callback }) {
  if (setLoader) {
    yield put(setLoadingGroupDetailsFlag(true));
  }
  checkParams({ params, keys: ['idData'] });
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${params.idData}/groups/${groupId}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (callback) {
      yield call(callback, data);
    }
    yield put(setGroupDetails(data));
  }
  const groupDetails = yield select(selectGroupDetails());
  Analytics.track('groups_engagement', {
    type: groupDetails?.is_default ? 'default' : 'private',
  });
  if (setLoader) {
    yield put(setLoadingGroupDetailsFlag(false));
  }
}
export function* fetchProjectMembers({ routeParams, params }) {
  checkParams({ params: routeParams, keys: ['idData'] });
  const { limit, offset, searchTerm } = params || {};

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    routeParams.idData
  }/members`;

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      limit: limit || 500,
      offset: offset || 0,
      search_term: searchTerm || '',
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setProjectMembers(data.members || []));
  }
}
export function* addGroupMembers({ members }) {
  const route = yield select(selectRouteParams());
  const group = yield select(selectCurrentGroup());
  const user_ids = members.map(member => member.user_id);
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${route.idData}/groups/${group?.group_id}/add-members`;

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_ids,
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    Analytics.track('groups_invite_member', {
      source: 'group_details',
      type: 'private',
    });
    yield call(fetchGroupMembers, {
      projectId: route.idData,
      groupId: group?.group_id,
    });
  }
}

function* updateGroup({ group, params }) {
  yield put(selectGroup(group));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${group.project_id}/groups/${group?.group_id}`;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: group.name,
      description: group?.description,
    }),
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    checkParams({ params, keys: ['idData'] });
    yield put(getGroupDetails(params, data.id));
    const groups = yield select(selectGroups());
    const oktaToken = localStorageUser.getOktaTokenStorage();

    yield put(
      setGroups(
        groups.map(gr => {
          if (gr?.group_id === data.id)
            return {
              ...gr,
              name: data.name,
              description: data?.description,
              last_action_tracking: groupUpdateActions.GroupNameEdited,
              last_message_date: moment.now(),
              last_action_tracking_creator_id: oktaToken?.accessToken?.claims?.fcUserId,
            };
          return gr;
        }),
      ),
    );
  }
}
function* updateDisplayedList({ groupId, target }) {
  const groups = yield select(selectGroups());
  const group = yield select(selectCurrentGroup());
  const isSelectedGroup = group?.group === groupId;
  const filteredList = groups.filter(el => el?.group_id !== groupId);
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${route.idData}/groups/search`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    const foundGroup = getArrayOrDefault(data?.groups).find(el => el?.group_id === groupId);
    if (isSelectedGroup) {
      localStorageUser.setGroupInGroupsStore({
        id: foundGroup?.group_id,
        group: {
          ...foundGroup,
          message_read_date: foundGroup.last_message_date,
        },
      });
    }
    if (target === groupUpdateAction.created) {
      yield call(fetchGroupMembers, {
        projectId: route.idData,
        groupId: foundGroup?.group_id,
      });
      yield put(selectGroup(foundGroup));
    }
    yield put(setGroups([foundGroup, ...filteredList]));
  }
}

export default function* initGroupsPageSaga() {
  yield takeLatest(GET_GROUPS, fetchGroups);
  yield takeLatest(UPDATE_GROUP, updateGroup);
  yield takeLatest(ADD_NEW_GROUP, addNewGroup);
  yield takeLatest(GET_GROUP_DETAILS, fetchGroupDetails);
  yield takeLatest(GET_PROJECT_MEMBERS, fetchProjectMembers);
  yield takeEvery(ADD_GROUP_MEMBERS, addGroupMembers);
  yield takeEvery(UPDATE_DISPLAYED_LIST, updateDisplayedList);
}
